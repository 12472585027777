module.exports={"Enums":[{"FullName":"MaxMind.Db.ObjectType","Name":"ObjectType","IsFlags":false,"Values":[{"Description":"Extended","Value":"Extended","UnderlayingValue":0},{"Description":"Pointer","Value":"Pointer","UnderlayingValue":1},{"Description":"Utf8String","Value":"Utf8String","UnderlayingValue":2},{"Description":"Double","Value":"Double","UnderlayingValue":3},{"Description":"Bytes","Value":"Bytes","UnderlayingValue":4},{"Description":"Uint16","Value":"Uint16","UnderlayingValue":5},{"Description":"Uint32","Value":"Uint32","UnderlayingValue":6},{"Description":"Map","Value":"Map","UnderlayingValue":7},{"Description":"Int32","Value":"Int32","UnderlayingValue":8},{"Description":"Uint64","Value":"Uint64","UnderlayingValue":9},{"Description":"Uint128","Value":"Uint128","UnderlayingValue":10},{"Description":"Array","Value":"Array","UnderlayingValue":11},{"Description":"Container","Value":"Container","UnderlayingValue":12},{"Description":"EndMarker","Value":"EndMarker","UnderlayingValue":13},{"Description":"Boolean","Value":"Boolean","UnderlayingValue":14},{"Description":"Float","Value":"Float","UnderlayingValue":15}]},
{"FullName":"MaxMind.Db.FileAccessMode","Name":"FileAccessMode","IsFlags":false,"Values":[{"Description":"MemoryMapped","Value":"MemoryMapped","UnderlayingValue":0},{"Description":"Memory","Value":"Memory","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Portal.Core.Utility.RetryExceptionType","Name":"RetryExceptionType","IsFlags":false,"Values":[{"Description":"Retriable","Value":"Retriable","UnderlayingValue":0},{"Description":"Ignorable","Value":"Ignorable","UnderlayingValue":1},{"Description":"Unrecoverable","Value":"Unrecoverable","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.Utility.NodeType","Name":"NodeType","IsFlags":false,"Values":[{"Description":"Root","Value":"Root","UnderlayingValue":0},{"Description":"Internal","Value":"Internal","UnderlayingValue":1},{"Description":"Leaf","Value":"Leaf","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.Domain.Validation.WellKnownValidationErrors","Name":"WellKnownValidationErrors","IsFlags":false,"Values":[{"Description":"ValueMandatory","Value":"ValueMandatory","UnderlayingValue":0},{"Description":"ValueTooShort","Value":"ValueTooShort","UnderlayingValue":1},{"Description":"ValueTooLong","Value":"ValueTooLong","UnderlayingValue":2},{"Description":"ValueNotUnique","Value":"ValueNotUnique","UnderlayingValue":3},{"Description":"ValueExceedsMinimum","Value":"ValueExceedsMinimum","UnderlayingValue":4},{"Description":"ValueExceedsMaximum","Value":"ValueExceedsMaximum","UnderlayingValue":5},{"Description":"ValueWrongData","Value":"ValueWrongData","UnderlayingValue":6}]},
{"FullName":"Mediaparts.Portal.Core.RemoteAppenderModels.LoggingAction","Name":"LoggingAction","IsFlags":false,"Values":[{"Description":"Store","Value":"Store","UnderlayingValue":0},{"Description":"Transmit","Value":"Transmit","UnderlayingValue":1},{"Description":"Discard","Value":"Discard","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.CMS.CMSController+RedirectReason","Name":"RedirectReason","IsFlags":false,"Values":[{"Description":"Default","Value":"Default","UnderlayingValue":0},{"Description":"Canonicalization","Value":"Canonicalization","UnderlayingValue":1},{"Description":"Alias","Value":"Alias","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Licensing.Services.Domain.ErrorFlags","Name":"ErrorFlags","IsFlags":true,"Values":[{"Description":"Default","Value":"Default","UnderlayingValue":0},{"Description":"Unread","Value":"Unread","UnderlayingValue":1},{"Description":"Attention","Value":"Attention","UnderlayingValue":2},{"Description":"Archived","Value":"Archived","UnderlayingValue":4},{"Description":"FollowupSent","Value":"FollowupSent","UnderlayingValue":8},{"Description":"Custom1","Value":"Custom1","UnderlayingValue":1024},{"Description":"Custom2","Value":"Custom2","UnderlayingValue":2048},{"Description":"Custom3","Value":"Custom3","UnderlayingValue":4096},{"Description":"Custom4","Value":"Custom4","UnderlayingValue":8192},{"Description":"Custom5","Value":"Custom5","UnderlayingValue":16384}]},
{"FullName":"Mediaparts.Licensing.Interface.Licensing.ReportType","Name":"ReportType","IsFlags":true,"Values":[{"Description":"Old","Value":"Old","UnderlayingValue":0},{"Description":"Information","Value":"Information","UnderlayingValue":1},{"Description":"Problem","Value":"Problem","UnderlayingValue":2},{"Description":"Fatal","Value":"Fatal","UnderlayingValue":4}]},
{"FullName":"Mediaparts.Licensing.Interface.Licensing.Availability","Name":"Availability","IsFlags":true,"Values":[{"Description":"Unavailable","Value":"Unavailable","UnderlayingValue":0},{"Description":"Developers","Value":"Developers","UnderlayingValue":1},{"Description":"Experts","Value":"Experts","UnderlayingValue":2},{"Description":"Beta","Value":"Beta","UnderlayingValue":4},{"Description":"Public","Value":"Public","UnderlayingValue":8}]},
{"FullName":"Mediaparts.Licensing.Interface.Licensing.DownloadAvailability","Name":"DownloadAvailability","IsFlags":false,"Values":[{"Description":"Available","Value":"Available","UnderlayingValue":0},{"Description":"NotAvailable","Value":"NotAvailable","UnderlayingValue":1},{"Description":"NoLicense","Value":"NoLicense","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Licensing.Interface.Licensing.ChangeType","Name":"ChangeType","IsFlags":false,"Values":[{"Description":"Bugfix","Value":"Bugfix","UnderlayingValue":0},{"Description":"Improvement","Value":"Improvement","UnderlayingValue":1},{"Description":"Information","Value":"Information","UnderlayingValue":2},{"Description":"Improvement","Value":"Improvement","UnderlayingValue":1},{"Description":"Bugfix","Value":"Bugfix","UnderlayingValue":0}]}],"ScanTime":"0:00:00","GenTime":"0:00:00.0005976"};